<template>
  <div class="order-details-page">
    <div class="container">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div class="status-box">
          <div class="status-text">
            <h2>{{ orderStatus[orderData.orderStatus] }}</h2>
            <span v-if="orderData.orderStatus == 50">金额将原路返回到账户</span>
            <span v-if="orderData.orderStatus == 10"
              >{{ countdown }}后订单将自动关闭</span
            >
          </div>
          <div class="icon">
            <img :src="payIcon" alt="" />
          </div>
        </div>
        <div class="order-info">
          <div class="img-box">
            <img
              :src="
                orderData.productType == 30
                  ? require('../assets/images/bill.png')
                  : orderData.productImg || defaultProductImg
              "
              alt=""
            />
          </div>
          <div class="goods-box">
            <div class="product-name-box">
              <h2 class="one-line-hidden">{{ orderData.productName }}</h2>
              <div class="product-price">
                <span class="unit">￥</span>
                <span class="price">{{
                  mathManage.formatMoney(orderData.productPrice, 2)
                }}</span>
              </div>
            </div>
            <div class="price-box">
              <div class="price">
                <span>
                  {{
                    renderAttrName(
                      orderData.productSpecName,
                      orderData.productAttrName
                    )
                  }}
                </span>
              </div>
              <span class="buy-num">x{{ orderData.productNum }}</span>
            </div>
            <div class="goods-type">
              <span>{{ productType[orderData.deliveryMode] }}</span>
            </div>
          </div>
        </div>
        <div
          class="service-box"
          v-if="orderData.orderStatus == 40 && serviceList[0]"
        >
          <a
            :href="serviceList[0].href"
            @click="serviceAction(serviceList[0])"
            v-if="
              orderData.afterSaleStatus != 30 &&
              orderData.afterSaleStatus != 50 &&
              orderData.isSendAfterSale
            "
            >{{
              orderData.afterSaleStatus == 10 || orderData.afterSaleStatus == 20
                ? "联系客服"
                : "申请售后"
            }}</a
          >
          <a
            v-if="orderData.afterSaleStatus"
            href="#"
            @click.stop.prevent="toServiceDetails"
            >{{ serviceStatus[orderData.afterSaleStatus] }}</a
          >
        </div>
        <div class="price-info">
          <div class="total-price">
            <span class="title">商品总价</span>
            <span class="price"
              >￥{{ mathManage.formatMoney(orderData.sumOrderPrice, 2) }}</span
            >
          </div>
          <div
            v-if="orderData.deductionType == 10"
            class="total-price total-juan"
          >
            <span class="title">优惠券</span>
            <span class="price">-￥{{ orderData.couponPrice || "-" }}</span>
          </div>
          <div class="pay-money">
            <span class="title">实付</span>
            <span class="money"
              >{{ mathManage.formatMoney(orderData.orderRealMoney, 2) }}元</span
            >
          </div>
        </div>
        <div class="account-info" v-if="orderData.deliveryMode == 10">
          <span class="title">充值账号</span>
          <span class="content">{{ orderData.orderRechargeAccount }}</span>
        </div>
        <div class="pay-info">
          <div class="pay-info-item">
            <span class="title">订单编号</span>
            <div class="content">{{ orderData.orderId }}</div>
            <span
              class="copy tag-read-account"
              :data-clipboard-text="orderData.orderId"
              @click="copy('tag-read-account')"
              >复制</span
            >
          </div>
          <div class="pay-info-item">
            <span class="title">下单时间</span>
            <div class="content">{{ orderData.orderCreateTime }}</div>
          </div>
          <div class="pay-info-item">
            <span class="title">支付方式</span>
            <div class="content">
              {{ payModeList[orderData.payMode] || "-" }}
            </div>
          </div>
          <div class="service-info" v-if="serviceList.length > 0">
            <div class="left-box" @click="serviceAction(serviceList[0])">
              <a :href="serviceList[0].href">
                <img class="service-img" :src="serviceList[0].src" alt="" />
                <span class="service-text">{{ serviceList[0].name }}</span>
              </a>
            </div>
            <div v-if="serviceList[1]" class="center-box"></div>
            <div
              v-if="serviceList[1]"
              class="right-box"
              @click="serviceAction(serviceList[1])"
            >
              <a :href="serviceList[1].href">
                <img class="service-img" :src="serviceList[1].src" alt="" />
                <span class="service-text">{{ serviceList[1].name }}</span>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="orderData.deliveryMode == 20 && orderData.orderStatus == 40"
          class="show-secret"
        >
          <span @click="clickShowSecret">查看卡密</span>
        </div>
      </van-pull-refresh>
    </div>

    <div :class="orderStartus === 10 ? 'footer three' : 'footer'">
      <span class="action-btn black" @click="toPage('/mall/orderList')"
        >订单列表</span
      >
      <span
        v-if="orderStartus == 10"
        class="action-btn black"
        @click="cancelOrder"
        >取消订单</span
      >
      <span
        v-if="orderStartus != 10"
        class="action-btn orange"
        @click="toPage('/mall/home')"
        >返回首页</span
      >
      <span
        v-if="orderStartus == 10"
        class="action-btn to-pay"
        @click="payOrder"
        >去支付</span
      >
    </div>
    <van-popup
      v-model="showCancelConfirm"
      :close-on-click-overlay="false"
      round
    >
      <div class="confirm-box">
        <h2>确认取消该笔订单？</h2>
        <div class="cancel-action">
          <span class="cancel-cancel" @click="showCancelConfirm = false"
            >我再想想</span
          >
          <span class="cancecl-ok" @click="cancelOk">取消订单</span>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showSecret"
      :close-on-click-overlay="false"
      round
      closeable
      close-icon="clear"
    >
      <div class="secret-box">
        <h2>查看卡密</h2>
        <div class="table-list">
          <div
            class="list-item"
            v-for="item in cardSecretList"
            :key="item.cardPwd"
          >
            <div class="card card-id">
              <span v-if="item.cardNumber" class="text"
                >卡号：{{ item.cardNumber }}</span
              >
              <span
                v-if="item.cardNumber"
                :class="`copy tag-read-id-${item.id}`"
                :data-clipboard-text="`${item.cardNumber}`"
                @click="copy(`tag-read-id-${item.id}`)"
                >复制</span
              >
            </div>
            <div class="card card-secret">
              <span class="text one-line-hidden" v-if="item.isLink">
                卡密：<a
                  :href="item.cardPwd"
                  class="one-line-hidden"
                  :style="{ fontSize: '0.24rem' }"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ item.cardPwd }}</a
                >
              </span>
              <span v-else class="text one-line-hidden"
                >卡密：{{ item.cardPwd }}</span
              >
              <span
                :class="`copy tag-read-secret-${item.id}`"
                :data-clipboard-text="`${item.cardPwd}`"
                @click="copy(`tag-read-secret-${item.id}`, item)"
                >复制</span
              >
            </div>
          </div>
        </div>
        <div class="cancel-action">
          <span class="cancecl-btn" @click="showSecret = false">返回</span>
        </div>
      </div>
    </van-popup>
    <van-image-preview v-model="showImg" :images="images" @change="onChange">
      <!-- <template v-slot:index>第{{ index }}页</template> -->
    </van-image-preview>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { Popup, PullRefresh, ImagePreview } from "vant";
import moment from "moment";
import { orderdetail, cancelorder, getOrderSecret } from "@/api/common";
import {
  orderStatus,
  productType,
  payModeList,
  serviceStatus,
} from "@/utils/dictionary";
import mathManage from "@/utils/mathManage";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
const payOk = require("../assets/images/pay-ok.png");
const payFail = require("../assets/images/pay-fail.png");
const payWait = require("../assets/images/pay-wait.png");

export default {
  name: "orderDetails",
  components: {
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      serviceStatus,
      images: [],
      showImg: false,
      index: 1,
      moment,
      mathManage,
      orderStartus: "",
      orderStatus,
      productType,
      payModeList,
      showCancelConfirm: false,
      showSecret: false,
      orderData: {},
      cardSecretList: [],
      refreshing: false,
      countdown: "",
      timer: null,
    };
  },
  computed: {
    ...mapState(["defaultProductImg", "serviceList"]),
    payIcon() {
      if (this.orderStartus == 40) {
        return payOk;
      }
      if (this.orderStartus == 50 || this.orderStartus == 60) {
        return payFail;
      }
      return payWait;
    },
  },
  created() {
    document.title = "订单详情";
    const orderId = this.$route.params.id;
    this.orderId = orderId;
    this.getDetails(orderId);
  },

  methods: {
    ...mapActions(["serviceAction"]),
    onChange(index) {
      this.index = index;
    },
    onRefresh() {
      this.getDetails(this.orderId);
    },
    // 查询详情
    async getDetails(orderId) {
      const res = await orderdetail({
        orderId,
      });
      if (res && res.code == 0) {
        this.orderData = res.data;
        this.orderStartus = res.data.orderStatus;
        this.refreshing = false;
        this.countDown();
      } else {
        this.$toast(res.message);
      }
    },
    // 复制账号
    copy(name, data = {}) {
      console.log(name);
      const clipboard = new Clipboard(`.${name}`);
      clipboard.on("success", (e) => {
        this.$toast.success("复制成功！");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$toast("该浏览器不支持自动复制！");
        // 释放内存
        clipboard.destroy();
      });
    },
    // 跳转页面
    toPage(path) {
      this.$router.push(path);
    },
    // 取消订单
    cancelOrder() {
      this.showCancelConfirm = true;
    },
    // 确认取消
    async cancelOk() {
      this.$loading("请稍等...");
      try {
        const res = await cancelorder({
          orderId: this.orderId,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.$toast.success("取消成功！");
          this.showCancelConfirm = false;
          this.toPage("/mall/orderList");
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },
    // 支付订单
    payOrder() {
      this.$router.push(`/mall/cashierDesk?id=${this.orderId}`);
    },
    // 查看卡密
    clickShowSecret() {
      this.showSecret = true;
      this.getOrderSecret();
    },

    preViewImg(url, e) {
      console.log(url);
      location.href = url;
      // window.open(url)
      // this.images = [url];
      // this.showImg = true;
    },

    // 获取卡密列表
    async getOrderSecret() {
      const res = await getOrderSecret({
        orderId: this.orderId,
      });
      if (res && res.code == 0) {
        this.cardSecretList = (res.data.list || []).map((item, i) => {
          return {
            ...item,
            id: `copy-${i}`,
            isLink:
              item.cardPwd &&
              (item.cardPwd.includes("https://") ||
                item.cardPwd.includes("http://")),
          };
        });
      } else {
        this.$toast(res.message);
      }
    },

    toServiceDetails() {
      this.$router.push(`/mall/serviceDetails/${this.orderData.orderId}`);
    },

    countDown() {
      if (this.orderData.orderStatus == 10) {
        let orderCreateTime = moment(this.orderData.orderCreateTime); // 下单时间
        let currentTime = moment(); // 当前时间
        let time = parseInt(1800 - currentTime.diff(orderCreateTime, "second"));
        this.timer = setInterval(() => {
          if (time > 0) {
            time -= 1;
          } else {
            time = 0;
            clearInterval(this.timer);
          }
          let minute = parseInt(time / 60);
          let second = parseInt(time % 60);
          this.countdown = `${minute}分钟${second}秒`;
        }, 1000);
      }
    },

    renderAttrName(productSpecName, productAttrName) {
      if (!productSpecName && !productAttrName) return "";
      if (!productAttrName) return productSpecName;
      return `${productSpecName};${productAttrName}`;
    },
  },

  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-image-preview {
  background-color: #000 !important;
  z-index: 99999 !important;
}

.order-details-page {
  height: 100%;
  overflow: hidden;

  /deep/ .van-overlay {
    z-index: 999 !important;
  }
  .container {
    height: (100vh - 2.36rem);
    // overflow-y: auto;
    margin-bottom: 1.2rem;
    overflow: hidden;

    /deep/ .van-pull-refresh {
      height: (100vh - 2.36rem);
      overflow-y: auto;
    }

    .status-box {
      height: 1.36rem;
      // background: #ff4800;
      background-image: url("../assets/images/ddbg.png");
      background-size: 100% 100%;
      padding: 0 0.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .status-text {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
          font-size: 0.36rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        span {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-top: 0.01rem;
          opacity: 0.8;
        }
      }
      .icon {
        width: 1.66rem;
        height: 1.66rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .order-info {
      display: flex;
      padding: 0.32rem 0.4rem;
      box-sizing: border-box;
      background-color: #fff;

      .img-box {
        width: 2.04rem;
        height: 1.26rem;
        border-radius: 0.08rem;
        overflow: hidden;
        margin-right: 0.2rem;
        // background-color: chocolate;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .goods-box {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product-name-box {
          display: flex;
          align-items: center;
          height: 0.3rem;
          overflow: hidden;

          h2 {
            flex: 1;
            font-size: 0.26rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .product-price {
            padding-left: 0.2rem;
            height: 100%;
            margin-left: 0.2rem;
            display: flex;
            justify-content: flex-end;

            .unit {
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff503f;
              padding-top: 0.02rem;
            }
            .price {
              font-size: 0.28rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ff503f;
            }
          }
        }

        .price-box {
          display: flex;
          justify-content: space-between;

          .price {
            display: flex;
            align-items: center;

            span {
              font-size: 0.22rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }

          .buy-num {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }

        .goods-type {
          display: flex;
          align-items: center;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 0.4rem;
            background: #f9f9f9;
            border-radius: 0.04rem;
            padding: 0 0.08rem;
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }

    .service-box {
      height: 0.92rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #fff;
      padding: 0 0.4rem;

      a {
        width: 1.6rem;
        height: 0.56rem;
        border-radius: 0.28rem;
        border: 0.01rem solid #cacaca;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.2rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .price-info {
      background-color: #fff;
      padding: 0.44rem 0.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .total-price {
        display: flex;
        justify-content: space-between;

        .title {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #565656;
        }
        .price {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
        }
      }

      .total-juan {
        margin-top: 0.26rem;

        .price {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff503f;
        }
      }

      .pay-money {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0.53rem;

        .title {
          font-size: 0.28rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
        }
        .money {
          font-size: 0.32rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff503f;
          margin-left: 0.06rem;
        }
      }
    }

    .account-info {
      height: 0.92rem;
      background: #ffffff;
      margin-top: 0.2rem;
      display: flex;
      align-items: center;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      padding: 0 0.4rem;

      .title {
        width: 1.44rem;
      }
      .content {
        flex: 1;
      }
    }

    .pay-info {
      background: #ffffff;
      margin-top: 0.2rem;
      padding: 0.32rem 0.4rem 0;

      .pay-info-item {
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 0.32rem;
        &:last-child {
          margin-bottom: 0;
        }

        .title {
          width: 1.44rem;
          display: flex;
          align-items: center;
        }
        .content {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .copy {
          width: 0.6rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff4800;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .service-info {
        border-top: 0.02rem solid #f5f5f5;
        display: flex;
        align-items: center;

        .left-box {
          flex: 1;
          height: 0.8rem;

          a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .center-box {
          width: 0.02rem;
          height: 0.48rem;
          background-color: #f5f5f5;
        }
        .right-box {
          flex: 1;
          height: 0.8rem;

          a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .service-img {
          width: 0.4rem;
          height: 0.4rem;
        }

        .service-text {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-left: 0.2rem;
        }
      }
    }

    .show-secret {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.48rem;
      span {
        width: 2.8rem;
        height: 0.64rem;
        background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
        border-radius: 0.44rem;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.02rem;
      }
    }
  }

  .footer {
    width: 100%;
    height: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    padding: 0 0.42rem;
    position: fixed;
    bottom: 0;
    z-index: 10;

    .action-btn {
      width: 3.18rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      border: 0.02rem solid #e8e8e8;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999;

      &.black {
        border-color: #e8e8e8;
        color: #999;
      }
      &.orange {
        border-color: #ff593e;
        color: #ff593e;
      }
      &.to-pay {
        background: #ff3a20;
        color: #ffffff;
        border-color: #ff3a20;
        letter-spacing: 0.02rem;
      }
    }

    &.three {
      .action-btn {
        width: 2.04rem;
      }
    }
  }

  .confirm-box {
    width: 5.9rem;
    height: 2.88rem;
    background: #ffffff;
    border-radius: 0.2rem;
    padding: 0 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    .cancel-action {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.72rem;

      span {
        width: 2.4rem;
        height: 0.72rem;
        border-radius: 0.36rem;
        border: 0.02rem solid #e8e8e8;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.01rem;

        &.cancecl-ok {
          background: #ff4800;
          border-color: #ff4800;
          color: #fff;
        }
      }
    }
  }

  .secret-box {
    width: 6.54rem;
    height: 6.46rem;
    background: #ffffff;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-top: 0.36rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .table-list {
      height: 3.2rem;
      overflow-y: auto;
      margin-top: 0.68rem;
      padding: 0 0.48rem;

      .list-item {
        height: 1.6rem;
        border-top: 0.01rem solid #e8e8e8;
        display: flex;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;

        &:last-child {
          border-bottom: 0.01rem solid #e8e8e8;
        }

        .card {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.card-secret {
            margin-top: 0.16rem;
          }

          .text {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }

          .copy {
            width: 1rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff4800;
            text-align: right;
          }
        }
      }
    }

    .cancel-action {
      height: 0.72rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.64rem;

      .cancecl-btn {
        width: 2.4rem;
        height: 0.72rem;
        border-radius: 0.36rem;
        border: 0.02rem solid #b1b1b1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        letter-spacing: 0.01rem;
      }
    }
  }
}
</style>